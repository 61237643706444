import React from "react";
import HomePage from "./Pages/Home";
import FAQ from "./Pages/FAQ";
import AuthLayout from "./Pages/auth/AuthLayout";
import LoginPage from "./Pages/auth/LoginPage";
import ProtectedLayout from "./Pages/protected/ProtectedLayout";
import AddPatient from "./Pages/protected/AddPatient";
import AddPatientDetails from "./Pages/protected/AddPatientDetails";
import ReportPage from "./Pages/protected/ReportPage";
import SystemPage from "./Pages/protected/SystemPage";
import Tests from "./Pages/protected/Tests";
import TestPage from "./Pages/protected/TestPage";
import PaymentLayout from "./Pages/protected/payment/PaymentLayout";
import Method1 from "./Pages/protected/payment/Method1";
import Method2 from "./Pages/protected/payment/Method2";
import AddressProof from "./Pages/protected/payment/AddressProof";
import Confirmation from "./Pages/protected/payment/Confirmation";
import AllRoutes from "./Navigation/Routes";

const App = () => {
  return (
    <div className="App">
      <AllRoutes/>
    </div>
  )
}

export default App;
