import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Navbar from '../../Components/Navbar'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';

export default function ProtectedLayout() {
    const isAuthenticated = useSelector(state => state.authSlice.isAuthenticated);
    const token = localStorage.getItem('cookieFallback');
    const location = useLocation()

    if (!(isAuthenticated || token)) {
        console.log(isAuthenticated)
        console.log(token)
        return <Navigate to={'/auth/login'} />
    }

    return (
        <div className='w-full h-full bg-[#C7CEE5]'>
            {
                location.pathname != '/search-patient' && (
                    <Navbar />
                )
            }
            <Outlet />
            <ToastContainer/>
        </div>
    )
}
