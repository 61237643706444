import React, { useState, useRef } from 'react';
import axios from 'axios'; // For making API requests
import RecordRTC from 'recordrtc'; // For recording audio

const CoughModal = ({ isOpen, onClose, title }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [coughValidationMessage, setCoughValidationMessage] = useState(null);
  const [error, setError] = useState(null);
  const recorderRef = useRef(null); // Ref for RecordRTC recorder

  if (!isOpen) return null;

  // Function to start recording
  const startRecording = async () => {
    try {
      // Request microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Use RecordRTC's StereoAudioRecorder for recording in WAV format
      const options = {
        mimeType: 'audio/wav',
        sampleRate: 44100, // Set the sample rate to 44100 Hz
      };
      const recorder = new RecordRTC.StereoAudioRecorder(stream, options);
      recorderRef.current = recorder;

      recorder.record(); // Start recording
      setIsRecording(true);
      console.log("Recording started.");
    } catch (error) {
      console.error("Error accessing microphone:", error);
      alert("Microphone access is required to record audio.");
    }
  };

  // Function to stop recording
  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stop((blob) => {
        processRecording(blob); // Process the recorded audio blob
      });
      setIsRecording(false);
      console.log("Recording stopped.");
    }
  };

  // Function to process the recorded audio blob
  const processRecording = (blob) => {
    const formData = new FormData();
    formData.append('coughsoundfile', blob, 'audio.wav');

    // Send the recorded audio to the verifycough API
    verifyCoughSample(formData);

    // Create a URL to play back the audio
    const audioUrl = URL.createObjectURL(blob);
    setAudioURL(audioUrl);
  };

  // Function to verify cough sample by sending it to the API
  const verifyCoughSample = async (formData) => {
    try {
      const response = await axios.post('https://swaasa.sandbox.swaasa.ai/api/verifycough', formData, {
        headers: {
          accessToken: "eyJhbGciOiJIUzUxMiIsImlhdCI6MTcyMTcxODU2OSwiZXhwIjoxOTgwOTE4NTY5fQ.eyJ1c2VySWQiOm51bGwsImxvZ2luSWQiOiJkaXNlYXNlX3ByZWRAc3dhYXNhLmFpIiwiclR5cGUiOiJhZG1pbiIsInBsYW4iOiJ2aXRhbHMscGx1cyx0Yixjb3BkLGFzdGhtYSxzcGlyb21ldHJ5IiwiYWNjZXNzVG9rZW4iOm51bGwsInVzZXJEZXRhaWxzIjpudWxsLCJwYXJlbnRJZCI6bnVsbCwiY29uZmlndXJhdGlvbiI6e319.w8l-tbdal_61Omqw6qB5lE6lsNqr-SN3HKcHsdf-xbiF61PXiWO71MzwX411gBAFk6QhWbg3BH9Mco57jyb07A",// Replace with your actual access token
          Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk", // Replace with your actual JWT token
        },
        withCredentials: true, // Ensure credentials are included in the request
      });

      console.log(response); // Log the entire response for debugging
      if (response.data && response.data.data && response.data.data.isValidCough) {
        setCoughValidationMessage("Cough is valid.");
      } else {
        setCoughValidationMessage(response.data.message || "Cough is not valid.");
      }
    } catch (error) {
      console.error("Error verifying cough:", error);
      setCoughValidationMessage("Error verifying the cough sample.");
      setError(error);
    }
  };

  // Function to reset the recording state
  const resetRecordingState = () => {
    setIsRecording(false);
    setAudioURL("");
    setCoughValidationMessage(null);
    if (recorderRef.current) {
      recorderRef.current = null;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-1/3">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button
            onClick={() => {
              resetRecordingState();
              onClose();
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <div className="p-4">
          <h2>Audio Recorder</h2>
          <button
            onClick={isRecording ? stopRecording : startRecording}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            {isRecording ? "Stop Recording" : "Start Recording"}
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 ml-4"
            onClick={resetRecordingState}
          >
            Reset
          </button>
          {isRecording && <div className="text-red-500 mt-2">Recording...</div>}
          {audioURL && (
            <div className="mt-4">
              <h3>Recorded Audio:</h3>
              <audio src={audioURL} controls />
            </div>
          )}

          {/* Display the cough validation result */}
          {coughValidationMessage && <p>{coughValidationMessage}</p>}
          {error && <p className="text-red-500">Error: {error.message}</p>}
        </div>
        <div className="p-4 border-t flex justify-end">
          <button
            onClick={() => {
              resetRecordingState();
              onClose();
            }}
            className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoughModal;
