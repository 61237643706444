import React, { useState } from "react";
import user from "../../../src/assets/user.png";
import cross from "../../assets/cross.svg";
import gender from "../../assets/gender.svg";
import imgupload from "../../assets/imgupload.svg";
import fileimport from "../../assets/fileimport.svg";
import addpatient from "../../assets/addpatient.svg";
import help from "../../assets/help.svg";
import sidevector from "../../assets/sidevector.svg";
import patientname from "../../assets/patientname.svg";
import emailid from "../../assets/emailid.svg";
import contact from "../../assets/contact.svg";
import patientage from "../../assets/patientage.svg";
import patientadhar from "../../assets/patientadhar.svg";
import labnumber from "../../assets/labnumber.svg";
import place from "../../assets/place.svg";
import patientid from "../../assets/patientid.svg";
import axios from "axios";
import API_ENDPOINTS from "../../service/api";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import FAQ from "../FAQ";
const AddPatient = () => {
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState("");

  const handleRadio = (e) => {
    const { value } = e.target;
    setSelectedGender(value);
    setForm((form) => ({
      ...form,
      Gender: value, // Update Gender in form state
    }));
  };
  const [form, setForm] = useState({
    Adhaar: "",
    Adhaar_Image: "",
    dob: "", // Changed from Age to dob
    EmailId: "",
    Gender: selectedGender,
    Name: "",
    Place: "",
    contactNumber: "",
  });

  console.log(form);
  function handleChangeEvent(event) {
    event.preventDefault();
    const { name, value } = event.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  }

  function handleChangeEvent(event) {
    event.preventDefault();
    const { name, value } = event.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  }

  async function handleSubmit() {
    if (
      form.dob.trim() && // Check for dob instead of Age
      form.Gender.trim() &&
      form.Name.trim() &&
      form.contactNumber.trim()
    ) {
      try {
        // Function to calculate age from dob
        const calculateAge = (dob) => {
          if (!dob) return null;

          const dobDate = new Date(dob);
          const today = new Date();
          let age = today.getFullYear() - dobDate.getFullYear();
          const monthDifference = today.getMonth() - dobDate.getMonth();

          if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < dobDate.getDate())
          ) {
            age--;
          }

          return age;
        };

        // Calculate age
        const age = calculateAge(form.dob);
        console.log(age);
        const resp = await axios.post(
          API_ENDPOINTS.addPatient,
          {
            Adhaar: form.Adhaar,
            Adhaar_Image:
              "https://adharimg.s3.ap-south-1.amazonaws.com/adhaar/Report-1725361112006.jpeg",
            Age: age, // Pass the calculated age
            EmailId: form.EmailId,
            Gender: form.Gender,
            Name: form.Name,
            Place: form.Place,
            contactNumber: form.contactNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer YOUR_TOKEN_HERE", // Replace with actual token
            },
          }
        );
        const obj = {
          Adhaar: form.Adhaar,
          Adhaar_Image:
            "https://adharimg.s3.ap-south-1.amazonaws.com/adhaar/Report-1725361112006.jpeg",
          Age: age, // Pass the calculated age
          EmailId: form.EmailId,
          Gender: form.Gender,
          Name: form.Name,
          Place: form.Place,
          contactNumber: form.contactNumber,
        };

        console.log(obj);

        if (!resp?.data.error) {
          console.log("Patient added successfully.");
          toast.success("Patient Added!", {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate("/add-patient");
        } else {
          toast.error("Adding Patient Failed!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.log(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || "An error occurred", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.error("Please fill out all fields.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  return (
    <div className="bg-[#C7CEE5] min-h-screen h-full">
      <div className=" p-4 sm:p-8 lg:py-10 lg:px-28">
        {/* Add Patient Form */}
        <div className="bg-white rounded-3xl h-[70%] w-full  shadow-lg p-6 lg:p-8 mx-auto z-10 relative">
          <Link
            to="/add-patient"
            className="flex justify-end -mt-8 -mr-8 lg:mt-[-20px] lg:mr-[-20px]"
          >
            <img src={cross} alt="crossicon" />
          </Link>

          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-[#9669E0] mb-6">
            Add Patient
          </h1>
          <div className="grid grid-cols-1 mr-24 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-12 lg:mt-20">
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={patientname} alt="patientnameicon" />
              </span>
              <input
                name="Name"
                required
                type="text"
                value={form.Name}
                onChange={handleChangeEvent}
                placeholder="Patient name"
                className="pl-10 pr-4 p-3 w-80 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={patientid} alt="patientnameicon" />
              </span>
              <input
                name="Adhaar"
                type="text"
                value={form.Adhaar}
                onChange={handleChangeEvent}
                placeholder="Patient Aadhaar Number"
                className="pl-10 pr-4 p-3 w-80 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={labnumber} alt="patientnameicon" />
              </span>
              <input
                type="text"
                onChange={handleChangeEvent}
                placeholder="Lab Number"
                className="pl-10 pr-4 p-3 w-40 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={patientage} alt="patientnameicon" />
              </span>
              <input
                name="dob" // Changed name to dob
                type="date" // Changed type to date
                value={form.dob} // Changed from form.Age to form.dob
                onChange={handleChangeEvent}
                placeholder="Select date of birth" // Updated placeholder
                className="pl-10 pr-4 p-3 w-40 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={emailid} alt="patientnameicon" />
              </span>
              <input
                name="EmailId"
                type="text"
                value={form.EmailId}
                onChange={handleChangeEvent}
                placeholder="Patient Email ID"
                className="pl-10 pr-4 p-3 w-72 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>
            {/* 
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={patientid} alt="patientnameicon" />
              </span>
              <input
                name="PatientId"
                type="text"
                onChange={handleChangeEvent}
                placeholder="Patient ID"
                className="pl-10 pr-4 p-3 w-40 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>  */}

            {/* Gender */}
            <div className="flex text-sm items-center space-x-2 mt-4">
              <img src={gender} alt="gendericon" />
              <span className="font-bold text-[#5C5CAB99]">Gender</span>
              <label className="space-x-0 cursor-pointer">
                <input
                  type="radio"
                  required
                  onChange={handleRadio}
                  name="Gender"
                  value="male"
                  checked={selectedGender === "male"}
                />
                <span>MALE</span>
              </label>
              <label className="space-x-0 cursor-pointer">
                <input
                  type="radio"
                  required
                  onChange={handleRadio}
                  name="Gender"
                  value="female"
                  checked={selectedGender === "female"}
                />
                <span>FEMALE</span>
              </label>
              <label className="space-x-0 cursor-pointer">
                <input
                  type="radio"
                  required
                  onChange={handleRadio}
                  name="Gender"
                  value="other"
                  checked={selectedGender === "other"}
                />
                <span>OTHER</span>
              </label>
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={place} alt="placeicon" />
              </span>
              <input
                name="Place"
                type="text"
                value={form.Place}
                onChange={handleChangeEvent}
                placeholder="Place"
                className="pl-10 pr-4 p-3 w-40 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={contact} alt="patientnameicon" />
              </span>
              <input
                name="contactNumber"
                type="text"
                required
                value={form.contactNumber}
                onChange={handleChangeEvent}
                placeholder="Contact no"
                className="pl-10 pr-4 p-3 w-80 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>
          </div>

          {/* Upload Patient Photo */}
          <div className="absolute flex flex-col items-center right-4 lg:right-10 top-4 lg:top-[69px]">
            <div className="w-24 h-24 bg-[#D7D4F8] rounded-full flex border-4 border-[#9669E0] items-center justify-center">
              <img
                src={user}
                alt="Upload Patient pic"
                className="w-23 h-23 items-center"
              />
            </div>
            <div className="flex mt-2">
              <img src={imgupload} alt="imguploadicon" />
              <p className="text-sm lg:text-base text-[#9669E0]">
                Upload Patient Photo
              </p>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
            {/* File Upload */}
            <div className="mt-6 p-4 bg-[#D7D4F8] w-full lg:w-[45%] rounded-lg flex items-center justify-center flex-col">
              <img src={fileimport} alt="importicon" />
              <p className="text-sm lg:text-base mt-2">Upload Report</p>
              <p className="text-sm lg:text-base">OR</p>
              <button className="mt-2 w-28 lg:w-[111.55px] h-10 px-4 py-2 bg-[#9669E0] text-white rounded-lg">
                Browse
              </button>
              <p className="mt-2 text-xs lg:text-[9.12px] text-black text-center">
                *Provide Patient Aadhaar Card (IMAGE/PDF)
              </p>
            </div>

            {/* Add Patient Button */}
            <button
              onClick={handleSubmit}
              className="mt-6  lg:mt-32 text-sm lg:text-base font-bold h-12 w-56  px-8 py-3 bg-[#9669E0] text-white rounded-lg flex items-center justify-center space-x-3 lg:space-x-5"
            >
              <p>Add Patient </p>
              <img src={addpatient} alt="addpatienticon" />
            </button>
          </div>
        </div>

        {/* Background Image */}

        {/* Help Icon */}
        <Link to="../faq" className="fixed z-10 bottom-8 right-28 ">
          <img src={help} alt="helpicon" className="w-10 lg:w-12" />
        </Link>
      </div>
      <ToastContainer />
      <div className="fixed bottom-0 right-0 z-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>
    </div>
  );
};

export default AddPatient;
