const API_ENDPOINTS = {
  signUpApi: "https://api.superceuticals.in/technicians/login",
  addPatient: "https://api.superceuticals.in/patient/add-patient",
  getTestResults: "https://api.superceuticals.in/patient/get-patientTest/SC23F000005",
  addTestReport: "https://api.superceuticals.in/test/add-testResult",

  bodyCompostionApi: "https://localhost:3003/api/data",
};

export default API_ENDPOINTS;
