import React, { useState, useEffect } from "react";
import HeartRate from "../../assets/icons/heart-rate-2.svg";
import Temp from "../../assets/icons/temp.svg";
import BloodPressure from "../../assets/icons/blood-pressure.svg";
import BloodAnalysis from "../../assets/icons/body-analysis.svg";
import Weight from "../../assets/icons/weight.svg";
import DefaultBodyCompositionImage from "../../assets/default-body-composition-image.png";
import HeightIcon from "../../assets/icons/height.svg";
import angleDown from "../../assets/icons/downarrow.svg";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTests, addReferedBy } from "../../Redux_Services/slices/testSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function BodyComposition() {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.testSlice.testsData || []);
  const referedBy = useSelector((state) => state.testSlice.referedBy || "");
  const patientDetails = useSelector((state) => state.patientSlice.patientData);
  const [referredBy, setReferredBy] = useState("")
  const [testData, setTestData] = useState(tests);
  const [update, setUpdate] = useState(false);
  const [temperatureTest, setTemperatureTest] = useState(false);
  const [bloodPressureTest, setBloodPressureTest] = useState(false);
  const [heartRateTest, setHeartRateTest] = useState(false);
  const [formData, setFormData] = useState({
    temperature: "",
    bloodPressure: "",
    heartRate: "",
    height: "",
    weight: "",
  });
  const [loading, setLoading] = useState(false);

  // const [Height, setHeight] = useState(null)
  console.log(patientDetails);
  useEffect(() => {
    calibrateWeight("prepare_weight");
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [dropValue, setDropValue] = useState("Body Type");
  const [res, setres] = useState({});

  const [gender, setGender] = useState("Male"); // Assuming default gender is Male

  let flag = false;

  const {
    heartRate = "Heart Rate",
    temperature = "Temperature",
    bloodPressure = "Blood Pressure",
    height = "Height",
    weight = "Weight",
    bmi = "BMI",
  } = res;
  const impe = "Impedence Data";

  const [form, setForm] = useState(" ");
  const [data, setData] = useState(null);
  // const [count, setCount] = useState(0);
  const [impedanceData, setImpedanceData] = useState({
    body_oxygen_saturation: null,
    visceral_fat: null,
    body_fat: null,
    skeletal_muscle: null,
    body_age: null,
    resting_metabolism: null,
    bmi: null,
  });

  const navigate = useNavigate();
  const handleDropdownSelect = (value) => {
    setDropValue(value);
    setIsOpen(!isOpen);
  };
  const calculateMetrics = () => {
    console.log("__________________inside calculate");
    const heightInMeter = formData.height / 100; // Convert cm to meters
    const isMale = gender === "Male";
    const factor = isMale ? 1 : 0;
    const age = patientDetails?.Age;

    if (formData.height && formData.weight && age) {
      const bmi = +(formData.weight / (heightInMeter * heightInMeter)).toFixed(
        2
      );

      const bodyFat = +(1.2 * bmi + 0.23 * age - 10.8 * factor - 5.4).toFixed(
        2
      );

      const bodyAge = +(age + (bmi - 22)).toFixed(2);

      const resting = +(
        isMale
          ? 10 * formData.weight + 6.25 * formData.height - 5 * age + 5
          : 10 * formData.weight + 6.25 * formData.height - 5 * age - 161
      ).toFixed(2);

      const skeletal = +(
        isMale ? formData.weight * 0.4 : formData.weight * 0.37
      ).toFixed(2);

      const visceralFat = +(0.1 * bmi + 0.23 * age - 5.4).toFixed(2);

      setImpedanceData({
        body_oxygen_saturation: null, // SpO2 can't be calculated, usually requires external measurement
        visceral_fat: visceralFat,
        body_fat: bodyFat,
        skeletal_muscle: skeletal,
        body_age: bodyAge,
        resting_metabolism: resting,
        bmi: bmi,
      });
 console.log("__________________inside calculate 5");
      console.log("Calculated Metrics:", {
        bmi,
        bodyFat,
        bodyAge,
        resting,
        skeletal,
        visceralFat,
      });

    } else {
      setImpedanceData({
        body_oxygen_saturation: null,
        visceral_fat: null,
        body_fat: null,
        skeletal_muscle: null,
        body_age: null,
        resting_metabolism: null,
      });

      console.log("__________________inside calculate 8");
    }

  };

  const handelTeare = () =>{
    calibrateWeight("prepare_weight");
  }

  const calibrateWeight = async (task_id) => {
    setLoading(true);
    try {
      const { data, status } = await axios.post(
        "http://localhost:5500/perform_task",
        {
          task_id,
          name: patientDetails.Name,
          age: patientDetails.Age,
          user_id: patientDetails.PatientID,
          body_type: "Average",
          gender: "Male",
        }
      );
      // Assuming the correct key is "message"
      
      const dataKey = Object.keys(data.message || {})[1];

      console.log("secondIndex", dataKey);

      if (status == 200 && data.success == true) {
        toast.success("weighing scale is ready! Click on Wait button and stand on scale", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
      } else {
        toast.error("Error In preparing weighing scale", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(`Error in preparing weighing scale ${error.message}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Error fetching data from server:", error);
      setLoading(false);
    }
  };

  const fetchDataFromServer = async (task_id) => {
    console.log("_____________", {
      task_id,
      name: patientDetails.Name,
      age: patientDetails.Age,
      userid: patientDetails.PatientID,
      bodyType: dropValue,
    });

    setLoading(true);

    if (dropValue !== "Body Type") {
      try {
        const { data, status } = await axios.post(
          "http://localhost:5500/perform_task",
          {
            task_id,
            name: patientDetails.Name,
            age: patientDetails.Age,
            user_id: patientDetails.PatientID,
            body_type: dropValue,
            gender: "Male",
          }
        );

        console.log("status code = ", status);

        if (data.success) {
          if (task_id === "measure_height") {
            setFormData((prevFormData) => ({
              ...prevFormData,
              height: data.message,
            }));
            toast.success("Operation successful!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            
            calculateMetrics();
            setLoading(false);
          } else {
            setFormData((prevFormData) => ({
              ...prevFormData,
              weight: data.message,
            }));
            toast.success("Operation successful!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            
            calculateMetrics();
            setLoading(false);
          }
        }

        if (!data.success) {
          toast.error("Opration faild!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(`Error fetching data from server: ${error.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.error("Error fetching data from server:", error);
      }
    } else {
      setLoading(false);
      toast.error("Please select a body type.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const fetchDataForImpedence = async (test_id) => {
    if (dropValue) {
      try {
        // Here you can call getTaskData directly
        const { data } = await axios.post(
          "http://localhost:5500/perform_task",
          {
            test_id,
            name: patientDetails.Name,
            age: patientDetails.Age,
            userid: patientDetails.PatientID,
            bodyType: dropValue,
          }
        );
        console.log("test_id = ", test_id);
        // Check if data is successful before setting state
        if (data.success === "true") {
          setImpedanceData(data);
        } else {
          console.error("Failed to fetch impedance data.");
        }
      } catch (error) {
        console.error("Error fetching impedance data:", error);
      }
    }
  };

  const defaultImpedanceData = {
    body_oxygen_saturation: null,
    visceral_fat: null,
    body_fat: null,
    skeletal_muscle: null,
    body_age: null,
    resting_metabolism: null,
  };

  function handleReset() {
    setImpedanceData(defaultImpedanceData);
    setres({
      heartRate: "Heart Rate",
      temperature: "Temperature",
      bloodPressure: " Blood Pressure",
      height: "Height",
      weight: "Weight",
      bmi: "BMI",
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    calculateMetrics();
  }, [formData.height, formData.weight]);
  useEffect(() => {
    // Check if tests is defined and is an array
    if (Array.isArray(tests)) {
      setTestData((prevTestData) =>
        prevTestData.map((test) => {
          if (Array.isArray(test.fields)) {
            console.log("Weight from formData:", formData.weight);

            return {
              ...test,
              fields: test.fields.map((field) => {
                switch (field.fieldName) {
                  case "Temperature":
                    return { ...field, value: formData.temperature };
                  case "Blood Pressure":
                    return { ...field, value: formData.bloodPressure };
                  case "Heart Rate":
                    return { ...field, value: formData.heartRate };
                  case "Height":
                    return { ...field, value: formData.height };
                  case "Weight":
                    return { ...field, value: formData.weight };
                  case "Body Oxygen Saturation":
                    return {
                      ...field,
                      value: impedanceData.body_oxygen_saturation,
                    };
                  case "BMI":
                    return { ...field, value: impedanceData.bmi };
                  case "Body Fat":
                    return { ...field, value: impedanceData.body_fat };
                  case "Visceral Fat":
                    return { ...field, value: impedanceData.visceral_fat };
                  case "Skeletal Muscle":
                    return { ...field, value: impedanceData.skeletal_muscle };
                  case "Body Age":
                    return { ...field, value: impedanceData.body_age };
                  case "Resting Metabolism":
                    return {
                      ...field,
                      value: impedanceData.resting_metabolism,
                    };
                  default:
                    return field;
                }
              }),
            };
          }

          return test;
        })
      );
    } else {
      console.warn("Tests is not an array:", tests);
    }
  }, [formData, tests, impedanceData]); // Updated dependency array

  console.log("check test stare = ", testData);

  function handleUpdate() {
    setUpdate(true);
    setTemperatureTest(true);
  }
  function handleHeart() {
    setUpdate(true);
    setHeartRateTest(true);
    
    // setForm("measure_heart_rate");
    // fetchDataFromServer("measure_heart_rate");
  }

  function handleTemperature() {
    
    setForm("measure_temperature");
    fetchDataFromServer("measure_temperature");
  }

  function handleBloodPressure() {
    
    setUpdate(true);
    setBloodPressureTest(true);
    // setForm("measure_bloodPressure");
    // fetchDataFromServer("measure_bloodPressure");
  }

  function handleHeight() {
    
    setForm("measure_height");
    fetchDataFromServer("measure_height");
  }

  function handleWeight() {
    
    setForm("measure_weight");
    fetchDataFromServer("measure_weight");
  }

  function handleBodyAnalysis() {
    
    setForm("measure_impedance");
    fetchDataFromServer("measure_impedance");
    //fetchDataForImpedence('measure_impedence');
  }

  function handleSpo2() {
    
    setForm("measure_bmi");
    fetchDataFromServer("measure_bmi");
  }

  const handleReferBy = (e)=>{
    setReferredBy(e.target.value)

  }

  const handleSave = () => {
    dispatch(addTests(testData));
    dispatch(addReferedBy(referredBy));
    navigate("/tests/testId/report");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mt-[07%] font-popins">
      <div className="w-full max-w-[80rem] mx-auto">
        <h1 className="text-3xl mt-[-3%] px-10 font-extrabold text-[#845BB3]">
          BODY COMPOSITION
        </h1>

        <div className="flex mb-[6%] items-start w-full justify-end  space-x-[10%] ">
          <div className="p-4 m-10 w-full max-w-fit flex relative">
            <div className="flex flex-col gap-8 justify-center cursor-pointer absolute left-0">
              <div
                onClick={heartRateTest ? null : handleHeart}
                className="flex flex-col items-center"
              >
                <img className="w-14 h-14" src={HeartRate} alt="Heart Rate" />
                {heartRateTest ? (
                  <input
                    type="text"
                    name="heartRate"
                    value={formData.heartRate}
                    onChange={handleInputChange}
                    className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] bg-[#D7D4F8] w-20 mt-2 text-center"
                  />
                ) : (
                  <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                    {heartRate}
                  </p>
                )}
              </div>

              <div
                onClick={temperatureTest ? null : handleUpdate} // Disable click event if temp is true
                className="flex flex-col items-center mr-19 mt-6"
              >
                <img className="w-14 h-14" src={Temp} alt="Temperature" />
                {temperatureTest ? (
                  <input
                    type="text"
                    name="temperature"
                    value={formData.temperature}
                    onChange={handleInputChange}
                    className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] bg-[#D7D4F8] w-20 mt-2 text-center"
                  />
                ) : (
                  <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                    {temperature}
                  </p>
                )}
              </div>

              <div
                onClick={bloodPressureTest ? null : handleBloodPressure}
                className="flex flex-col items-center"
              >
                <img
                  className="w-14 h-14"
                  src={BloodPressure}
                  alt="Blood Pressure"
                />
                {bloodPressureTest ? (
                  <input
                    type="text"
                    name="bloodPressure"
                    value={formData.bloodPressure}
                    onChange={handleInputChange}
                    className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] bg-[#D7D4F8] w-20 mt-2 text-center"
                  />
                ) : (
                  <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                    {bloodPressure}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center gap-2">
              <img
                className="w-[30rem] h-[30rem] object-contain mx-auto"
                src={DefaultBodyCompositionImage}
                alt="Current Body Composition"
              />

              <div className="flex flex-col items-center mt-6">
                <div
                  onClick={handleBodyAnalysis}
                  className="flex flex-col items-center"
                >
                  <img
                    className="w-14 h-14"
                    src={BloodAnalysis}
                    alt="Body Analysis"
                  />
                </div>
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  {impe}
                </p>

                <div className="flex flex-col items-center">
                  <button
                    onClick={handleReset}
                    className="text-red-500 text-sm px-2 py-1 rounded-md border border-red-500 w-fit mt-2"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div className="flex flex-col gap-8 justify-center absolute right-0">
                {(
                  <div className="flex flex-col items-center">
                    <button
                      onClick={handleHeight}
                      className="flex flex-col items-center"
                    >
                      <img
                        className="w-14 h-14"
                        src={HeightIcon}
                        alt="Height"
                      />
                    </button>
                    <input
                      type="text"
                      name="height"
                      value={formData.height}
                      onChange={handleInputChange}
                      placeholder="Height"
                      className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] bg-[#D7D4F8] w-20 mt-2 text-center"
                    />
                  </div>
                )}

                {(
                  <div className="flex flex-col items-center">
                    <button
                      onClick={handleWeight}
                      className="flex flex-col items-center "
                    >
                      <img className="w-14 h-14 " src={Weight} alt="Weight" />
                    </button>
                    <input
                      type="text"
                      name="weight"
                      value={formData.weight}
                      onChange={handleInputChange}
                      placeholder="Weight"
                      className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] bg-[#D7D4F8] w-20 mt-2 text-center"
                    />
                  </div>
                )}
                <div
                  onClick={handelTeare}
                  className="flex flex-col items-center mt-10 ml-4"
                >
                  <img className="w-14 h-14" src={Weight} alt="SpO2" />
                  <p className="text-sm px-2 py-1 rounded-md w-fit mt-2">
                    Tare Weight
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full ">
            <div className="w-96 mt-10">
              <div
                onClick={toggleDropdown}
                className="flex items-center justify-between border border-[#845BB3] rounded-md cursor-pointer px-2 py-1"
              >
                <p className="text-[#845BB3] text-sm">{dropValue}</p>
                <img className="w-6 h-6" src={angleDown} alt="Dropdown Icon" />
              </div>

              {isOpen && (
                <div className="mt-2 border border-[#845BB3] rounded-md bg-[#845BB3]shadow-md ">
                  {["athelete", "Average", "Fat"].map((option) => (
                    <div
                      key={option}
                      onClick={() => handleDropdownSelect(option)}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-10 w-full">
              <div className="text-2xl text-purple-800">
                <h2 className="font-stretch text-3xl font-bold ">
                  Impedence Data
                </h2>
              </div>
              <div className="flex flex-row gap-x-[15%] w-full  ">
                <div className="flex flex-col w-[50%]">
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Skeletal Muscle</h4>
                    <p className="text-purple-800 text-sm px-2 h-9  flex items-center justify-center   rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.skeletal_muscle}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">
                      Resting Metabolism
                    </h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.resting_metabolism}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Body Age</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.body_age}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col w-[50%]">
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">BMI</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.bmi}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Visceral Fat</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.visceral_fat}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Body Fat</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.body_fat}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex flex-col gap-3 mt-[10%]">
              <div className="flex">
              <p className="text-2xl text-[#845BB3]">REFERRED BY:</p>
            <input
              type="text"
              onChange={handleReferBy}
              value={referredBy}
              className="w-1/3 rounded-md bg-[#D7D4F8] text-center ml-2 px-3 py-2 text-base"
            />
              </div>
              <div className="flex">
              <button
                onClick={handleSave}
                className="bg-[#AB5C5C] rounded-md text-white text-base font-semibold py-2 px-2 flex items-center"
              >
                <span className="mr-2">GENERATE REPORT</span>
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="w-10 h-10 border-4 border-t-red-500 border-gray-300 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
}
