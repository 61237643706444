import React, { useEffect } from "react";
import logo from "../assets/superceuticals-logo.png";
import iso27 from "../assets/iso27.png";
import iso13 from "../assets/iso13.png";
import iso14 from "../assets/iso14.png";
import iso15 from "../assets/iso15.png";
import { useSelector } from "react-redux";
import { commentsData } from "../translate/testInstruction";
import { LipidProfile, HbA1c } from '../translate/testSummery'; // Import Lipid Profile and HbA1c components

const PatientReport = ({ patientDetails, testResults }) => {
  const refBy = useSelector((state) => state.testSlice.referedBy);

  const getTestComments = (testName, testFieldName) => {
    const matchingTest = commentsData.find((item) => {
      const testCategory = Object.keys(item)[0].toLowerCase();
      return testCategory === (testResults.fields.length === 1 
        ? testFieldName.toLowerCase().replace(/\s+/g, "") 
        : testName.toLowerCase().replace(/\s+/g, ""));
    });

    if (matchingTest) {
      const testCategory = Object.keys(matchingTest)[0];
      const testInfo = matchingTest[testCategory];

      return (
        <div className="mt-6 p-4 border bg-gray-100" key={testCategory}>
          <h3 className="text-lg font-bold">{testCategory} Test Information</h3>
          {Object.entries(testInfo).map(([section, content]) => (
            <div key={section} className="mt-2">
              <strong>{section.replace(/_/g, " ").toUpperCase()}:</strong>
              {typeof content === "object" && !Array.isArray(content) ? (
                Object.entries(content).map(([subSection, subContent]) => (
                  <div key={subSection} className="ml-4">
                    <strong>{subSection.replace(/_/g, " ").toUpperCase()}:</strong>
                    <p className="text-sm">{subContent}</p>
                  </div>
                ))
              ) : Array.isArray(content) ? (
                content.map((item, index) => (
                  <p key={index} className="text-sm">{item}</p>
                ))
              ) : (
                <p className="text-sm">{content}</p>
              )}
            </div>
          ))}
        </div>
      );
    }

    return null; // Return null if no matching test is found
  };

  useEffect(() => {
    const submitTestResult = async () => {
      const url = 'https://api.superceuticals.in/test/add-testResult';
      const body = {
        PatientID: patientDetails.PatientID || "SC23F000002",
        RefferedBy: refBy || "me",
        TechnicianID: "TECH433853",
        CentreID: "CEN18781001",
        MachineID: "MC0002"
      };
      testResults.fields.forEach(({ fieldName, value }) => {
        body[fieldName] = value;
      });

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer YOUR_TOKEN_HERE', // Use your valid token here
          },
          body: JSON.stringify(body)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Test result submitted successfully:', result);

      } catch (error) {
        console.error('Error submitting test result:', error);
      }
    };

    submitTestResult();
  }, [patientDetails, refBy, testResults.fields]); // Added dependencies to useEffect

  return (
    <div className="max-w-screen-lg mx-auto p-8 bg-white">
      {/* Header Section */}
      <div className="border p-4">
        <div className="flex justify-between items-start">
          <div>
            <img src={logo} alt="Logo" className="w-96 h-20 my-2" />
            <p className="text-sm">29, Infocity 2, Sector 33, Gurgaon, Haryana - 122005</p>
            <p className="text-sm">Gurgaon, Haryana 122022</p>
          </div>
          <div className="flex space-x-6 my-2">
            <img src={iso27} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso13} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso14} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso15} alt="ISO Icon" className="w-16 h-16" />
          </div>
        </div>

        {/* Patient Information */}
        <div className="mt-4 grid grid-cols-3 gap-4 text-sm">
          <div>
            <p><strong>Name:</strong> {patientDetails?.Name || ""}</p>
            <p><strong>Place:</strong> {patientDetails?.Place || ""}</p>
            <p><strong>Lab No:</strong> {patientDetails?.LabNo || ""}</p>
          </div>
          <div>
            <p><strong>Patient ID:</strong> {patientDetails?.PatientID || ""}</p>
            <p><strong>Age:</strong> {patientDetails?.Age || ""}</p>
            <p><strong>Gender:</strong> {patientDetails?.Gender || ""}</p>
          </div>
          <div>
            <p><strong>Ref By:</strong> {refBy || ""}</p>
            <p><strong>Date & Time:</strong> {patientDetails?.dateTime || ""}</p>
          </div>
        </div>
      </div>

      {/* Test Results Section */}
      <div className="border mt-6 p-4">
        <table className="min-w-full table-auto text-left text-sm">
          <thead>
            <tr className="border-b bg-purple-200">
              <th className="px-4 py-2 font-semibold text-sm">Test Category</th>
              <th className="px-4 py-2 font-semibold text-sm">Test Name</th>
              <th className="px-4 py-2 font-semibold text-sm">Results</th>
              <th className="px-4 py-2 font-semibold text-sm">Units</th>
              <th className="px-4 py-2 font-semibold text-sm">Bio. Ref. Interval</th>
            </tr>
          </thead>
          <tbody>
            {testResults?.fields?.map((field, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2">{testResults?.testFieldName}</td>
                <td className="px-4 py-2">{field?.fieldName}</td>
                <td className="px-4 py-2">{field?.value}</td>
                <td className="px-4 py-2">{field?.unitAndRange}</td>
                <td className="px-4 py-2">{field?.bioReferenceInterval || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Comments Section */}
      {testResults.fields.length > 0 && getTestComments(testResults?.testFieldName, testResults?.fields[0]?.fieldName)}

      {/* Display Lipid Profile and HbA1c components if applicable */}
      {testResults?.testFieldName === "Lipid Profile" && <LipidProfile />}
      {testResults?.fields?.some(field => field.fieldName === "HbA1c") && <HbA1c />}

      {/* Important Instructions */}
      <div className="border mt-6 p-4">
        <p className="font-bold text-sm">IMPORTANT INSTRUCTIONS</p>
        <ul className="list-disc list-inside text-sm">
          <li>Test results pertain to specimen submitted.</li>
          <li>All test results are dependent on the quality of the sample received.</li>
          <li>Investigations are only a tool to facilitate arriving at a diagnosis and should be clinically correlated by the referring physician.</li>
          <li>Report delivery may be delayed due to unforeseen conditions.</li>
          <li>Certain tests may require further testing at additional costs for derivation of exact value.</li>
          <li>Test results may show inter/Intra laboratory variations.</li>
          <li>The courts of Delhi shall have exclusive jurisdiction in all disputes/claims concerning test(s) and/or results of test(s).</li>
          <li>Test results are not valid for medico-legal purposes.</li>
        </ul>
      </div>

      <p className="text-center text-xs">--------------------End of Test--------------------</p>

      {/* Footer Section */}
      <div className="text-center bg-purple-200 mt-6">
        <p className="text-sm mt-2 italic">In case the results are unexpected or alarming, the patient is advised to contact the lab immediately for possible remedial action.</p>
        <p className="text-xs mt-1">"एक कदम स्वस्थ भारत की ओर"</p>
      </div>
    </div>
  );
};

export default PatientReport;
