// translations.js
export const translations = {
  english: {
    greeting: "Hello",
    farewell: "Goodbye",
    age: "Age",
    gender: "Gender",
    email: "Email",
    patientid: "PatientId",
    phone: "Phone",
    addPatient: "Add Patient",
    searchPatient: "Searched Patients Will Appear Here.",
    search: "Search",
    loading: "Loading ...",
    error: "error",
    noPatient:"No Patient Found."
  },
  hindi: {
    greeting: "नमस्ते",
    farewell: "अलविदा",
    age: "उम्र",
    gender: "लिंग",
    email: "ईमेल",
    patientid: "रोगी आईडी",
    phone: "फ़ोन",
    addPatient: "मरीज जोड़ें",
    searchPatient: "खोजे गए मरीज यहां दिखेंगे।",
    search: "खोज ...",
    loading: "लोड हो रहा है",
    error:"एरर",
    noPatient:"कोई मरीज़ नहीं मिला."


  },
};
