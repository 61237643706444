function formatDate(dateString) {
    // Create a Date object from the input date string
    const date = new Date(dateString);

    // Extract the day, month, and year
    const day = String(date.getDate()).padStart(2, '0'); // Pad single digit days with a leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    // Extract hours and minutes
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date as dd/mm/yy and time as HH:MM
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export default formatDate