import React, { useState, useEffect } from "react";
import reload from "../../assets/icons/reload.svg";
import beaker1 from "../../assets/icons/beaker-1.svg";
import beaker2 from "../../assets/icons/beaker-2.svg";
import report from "../../assets/icons/report.svg";
import compass from "../../assets/icons/compass.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTests, addReferedBy } from "../../Redux_Services/slices/testSlice";
import axios from "axios";
import CoughModal from "../../Components/CoughModal";
import CameraModal from "../../Components/CameraModal"

const GeneralTest = () => {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.testSlice.testsData || []);
  console.log("____________________ tests", (tests));
  const referedBy = useSelector((state) => state.testSlice.referedBy || "");
 
  const [testData, setTestData] = useState(tests);
  const [referby, setReferby] = useState(referedBy);
  const [results, setResults] = useState([]);
  const [hbA1cValue, setHbA1cValue] = useState(0);
  const [eAGValue, setEAGValue] = useState(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null); // Track which modal to open
  const [capturedImage, setCapturedImage] = useState(null); // State to hold captured image

  const handleCapture = (image) => {
    setCapturedImage(image);
    // Optionally, you can do something with the captured image here,
    // like sending it to a server or displaying it elsewhere
    console.log("Captured Image:", image);
  };

  const openModal = (fieldName) => {
    setActiveModal(fieldName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveModal(null); // Reset active modal when closing
  };
  
  useEffect(() => {
    setTestData(Array.isArray(tests) ? tests : []);
    setReferby(referedBy || "");
  }, [tests, referedBy]);
  useEffect(() => {
    if (hbA1cValue) {
      const eAG = (28.7 + hbA1cValue * 46.7).toFixed(2);
      setEAGValue(eAG);
      
      const updatedTestData = testData.map((test) => ({
        ...test,
        fields: test.fields.map((field) => {
          if (field.fieldName === "eAG") {
            return { ...field, value: eAG };
          }
          return field;
        }),
      }));
      setTestData(updatedTestData);
    }
  }, [hbA1cValue]);
  
  const handleInputChange = (sectionIndex, fieldIndex, value) => {
    const updatedTestData = testData.map((test, sIndex) => {
      if (sIndex === sectionIndex) {
        return {
          ...test,
          fields: test.fields.map((field, fIndex) => {
            if (fIndex === fieldIndex) {
              return {
                ...field,
                value: value,
              };
            }
            return field;
          }),
        };
      }
      return test;
    });
    setTestData(updatedTestData);
  };
//  console.log("testData = " , testData.fields[0].fieldName)

  const handleReferBy = (e) => {
    setReferby(e.target.value);
  };

  const fieldIds = testData.flatMap((test) =>
    test.fields.map((field) => field.fieldId)
  );

  const handlePreviousResults = async () => {
    try {
      const test_codes = fieldIds;
      const response = await axios.post(
        "https://api.superceuticals.in/test-two/get-latest-legacy-test/MC0001",
        { test_codes: test_codes },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk",
          },
        }
      );

      const data = response.data;
      console.log("Full data:", data.getTest);

      if (Array.isArray(data.getTest) && data.getTest.length > 0) {
        const resultMap = {};

        data.getTest.forEach((testItem) => {
          if (Array.isArray(testItem.mydata) && testItem.mydata.length > 0) {
            const testDataItem = testItem.mydata[0];
            const testCode = testDataItem.test_code;
            const result = testDataItem.result;
            if (testCode === "4548-4") {
              setHbA1cValue(result);
            }
            resultMap[testCode] = result;
          }
        });

        const updatedTestData = testData.map((test) => {
          return {
            ...test,
            fields: test.fields.map((field) => {
              if (field.code && resultMap[field.code]) {
                return {
                  ...field,
                  value: resultMap[field.code] || field.value,
                };
              }
              return field;
            }),
          };
        });

        setTestData(updatedTestData);
        setResults(Object.values(resultMap));
      } else {
        alert("No previous results found.");
      }
    } catch (error) {
      console.error("Error fetching previous results:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      alert("Failed to fetch previous results.");
    }
  };

  const handleLatestResult = async () => {
    console.log("Fetching latest results...");
    try {
      const test_codes = fieldIds;
      console.log("test codes:", test_codes);

      const response = await axios.post(
        "https://api.superceuticals.in/test-two/get-latest-test/MC0001",
        { test_codes: test_codes },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk",
          },
        }
      );

      const data = response.data;

      if (Array.isArray(data.getTest) && data.getTest.length > 0) {
        const resultMap = {};

        data.getTest.forEach((testItem) => {
          if (Array.isArray(testItem.mydata) && testItem.mydata.length > 0) {
            const testDataItem = testItem.mydata[0];
            const testCode = testDataItem.test_code;
            const result = testDataItem.result;
            resultMap[testCode] = result;
          }
        });

        const updatedTestData = testData.map((test) => {
          return {
            ...test,
            fields: test.fields.map((field) => {
              if (field.code && resultMap[field.code]) {
                return {
                  ...field,
                  value: resultMap[field.code] || field.value,
                };
              }
              return field;
            }),
          };
        });

        setTestData(updatedTestData);
        setResults(Object.values(resultMap));
      } else {
        alert("No previous results found.");
      }
    } catch (error) {
      console.error("Error fetching latest results:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      alert("Failed to fetch latest results.");
    }
  };

  const handleSave = () => {
    dispatch(addTests(testData));
    dispatch(addReferedBy(referby));
    navigate("/tests/testId/report");
  };

  const isAllFieldsFilled = testData.every((test) =>
    test.fields.every((field) => {
      return (
        field.value !== "" &&
        field.value !== undefined &&
        field.value !== null
      );
    })
  ) && referby.trim() !== ""; // Corrected logic to include referby check

  return (
    <div className="flex flex-col overflow-x-hidden items-center justify-center h-screen w-screen m-0">
      <div className="flex flex-col w-[80%] h-[85%] bg-white rounded-2xl my-5 mx-auto">
        <div className="relative w-full h-[15%] bg-[#845BB3] rounded-t-2xl flex items-center justify-center">
          <div className="font-poppins text-2xl font-semibold text-white">
            LATEST TEST RESULT
          </div>
        </div>
        <div className="w-full h-[25%] max-h-16 flex overflow-hidden">
          <div className="flex-grow-[4] border-r border-b border-[#845BB3] flex justify-center items-center">
            <img src={beaker1} alt="icon" className="w-7 h-7 mr-2" />
            <p className="text-center">Test Name</p>
          </div>
          <div className="flex-grow border-r border-b border-[#845BB3] flex justify-center items-center">
            <img src={beaker2} alt="icon" className="w-6 h-6 mr-2" />
            <p className="text-center">Results</p>
          </div>
          <div className="flex-grow border-b border-[#845BB3] flex justify-center items-center">
            <img src={compass} alt="icon" className="w-6 h-6 mr-2" />
            <p className="text-center">Unit and Range</p>
          </div>
        </div>
        <div className="flex-grow w-full flex flex-col gap-4 p-4 overflow-y-auto">
          {testData.map((test, sectionIndex) => (
            <div key={sectionIndex} className="w-full flex flex-col gap-4">
              <div className="flex items-center mb-4">
                <button className="bg-[#D7D4F8] rounded-md text-center px-4 py-2 font-poppins font-semibold text-[#845BB3] text-xl">
                  {test.testFieldName}
                </button>
              </div>
              <div className="w-full flex flex-col gap-4">
                {test.fields &&
                  Array.isArray(test.fields) &&
                  test.fields.map((field, index) => (
                    <div key={index} className="flex items-center">
                      <div className="w-2/3 text-left px-2 py-1 flex items-center font-poppins text-lg font-medium text-[#845BB3]">
                        {field.fieldName}
                      </div>
                      {(field.fieldName === "Test-COUGH" || field.fieldName === 'Cancer-Octoscope') ? (
                        <button
                        onClick={() => openModal(field.fieldName)} 
                          className="w-full max-w-[12rem] py-2 bg-[#845BB3] hover:bg-[#7535bd] text-white font-medium rounded-md"
                        >
                          Proceed for the Test
                        </button>
                      ) : (
                        <input
                          type="string"
                          value={
                            field.fieldName === "eAG"
                              ? eAGValue
                              : field.value || ""
                          }
                          onChange={(e) => {
                            if (field.fieldName === "HbA1c") {
                              setHbA1cValue(e.target.value);
                            }
                            handleInputChange(
                              sectionIndex,
                              index,
                              e.target.value
                            );
                          }}
                          className="w-1/6 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-none"
                        />
                      )}
                      <div className="w-1/4 text-right px-4 py-1 font-poppins text-sm font-medium text-[#845BB3]">
                        {field.unitAndRange}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="flex items-center mt-6">
                <button
                  className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                  onClick={handleLatestResult}
                >
                  <span className="mr-2">Fetch Latest Results</span>
                </button>
              </div>
              <div className="flex items-center mt-2">
                <button
                  className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                  onClick={handlePreviousResults}
                >
                  <span className="mr-2">Fetch Previous Results</span>
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="flex w-full justify-between items-center py-6 px-5 border-t-2">
          <div className="flex flex-row justify-start items-center">
            <p className="text-2xl text-[#845BB3]">REFERRED BY:</p>
            <input
              type="text"
              onChange={handleReferBy}
              value={referby}
              className="w-1/2 rounded-md bg-[#D7D4F8] text-center ml-2 px-3 py-2 text-base"
            />
          </div>
          <div>
            <button
              onClick={handleSave}
              className={`bg-${
                isAllFieldsFilled ? "[#845BB3]" : "[#D7D4F8]"
              } rounded-md text-white text-base font-semibold py-2 px-2 flex items-center`}
              disabled={!isAllFieldsFilled}
            >
              <span className="mr-2">GENERATE REPORT</span>
              <img src={report} alt="icon" className="w-6 h-6 align-middle" />
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && activeModal === "Test-COUGH" && (
        <CoughModal isOpen={isModalOpen} onClose={closeModal} title="Cough Modal" />
      )}
      {isModalOpen && activeModal === "Cancer-Octoscope" && (
        <CameraModal isOpen={isModalOpen} onClose={closeModal}  onCapture={handleCapture} />
      )}

      
    </div>
  );
};

export default GeneralTest;
