import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import testSlice from "./slices/testSlice";
import patientSlice from "./slices/patientSlice"
import langSlice from "./slices/langSlice";

const store = configureStore({
    reducer: {
        authSlice,
        testSlice,
        patientSlice,
        langSlice
    }
})

export default store