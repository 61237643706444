import React, { useState } from 'react';
import SelectTests from '../../Components/SelectTests';
import tests from '../../translate/translate';  // Assuming this is where you have your test data
import PatientProfile from '../../assets/patient-profile.png';
import EditIcon from '../../assets/icons/edit.svg';
import ManualTestingIcon from '../../assets/icons/manual-testing.svg';
import AutomaticTestingIcon from '../../assets/icons/automatic-testing.svg';
import UserIcon from '../../assets/icons/user2.svg';
import CrossIcon from '../../assets/icons/cross.svg';
import AgeIcon from '../../assets/icons/age.svg';
import FlaskIcon from '../../assets/icons/flask.svg';
import GenderIcon from '../../assets/icons/gender.svg';
import MapPinIcon from '../../assets/icons/mao-pin.svg';
import UserIdIcon from '../../assets/icons/user-id.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addTests } from '../../Redux_Services/slices/testSlice';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Tests() {

    const [selectedGroups, setSelectedGroups] = useState([]);
    const patientDetails = useSelector(state => state.patientSlice.patientData)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formatSelectedGroups = (selectedGroups, formattedTranslations) => {
        return selectedGroups.map(group => {
            const testGroup = formattedTranslations.find(test => test.testFieldName === group.name);

            return {
                testFieldName: group.name,
                fields: group.options.map(option => {
                    const field = testGroup.fields.find(f => f.fieldName === option);
                    return {
                        fieldName: field.fieldName,
                        unitAndRange: field.unitAndRange || "",
                        value: "",
                        fieldId: field.fieldId,
                        bioReferenceInterval: field.bioReferenceInterval
                    };
                })
            };
        });
    };
  
    const handleRedirect = () => {
        // Check if no tests are selected
        if (!selectedGroups.length) {
            toast.error('Please select at least one test.', {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
    
        // Check if "General" is among the selected test groups
        const isGeneralSelected = selectedGroups.some(group => group.name === "General");
    
        if (isGeneralSelected) {
            // Filter "General" test data
            const generalTestData = selectedGroups.filter(group => group.name === "General");
    
            // Dispatch Redux action to update state for "General" test
            dispatch(addTests(formatSelectedGroups(generalTestData, tests)));
    
            navigate('/body-composition');
            return;
        }
    
        // For other test groups, dispatch the action and navigate to /general-test
        dispatch(addTests(formatSelectedGroups(selectedGroups, tests)));
        navigate('/general-test');
    };
    
    

    return (
        <div className='font-popins flex flex-col justify-center w-full max-w-7xl mx-auto'>
            <div className='w-full max-w-4xl mx-auto rounded-2xl px-6 py-5 my-16 bg-white flex items-center justify-between shadow-lg relative'>
                {/* Close Button */}
                <Link to={'/add-patient'} className='absolute top-3 right-3 cursor-pointer'>
                    <img src={CrossIcon} alt="Close Icon" />
                </Link>
                {/* Patient Details */}
                <div>
                    <h1 className='text-3xl font-bold text-[#845BB3] mb-4 px-2'>
                        Patient Details
                    </h1>
                    <div className='mt-2 flex flex-wrap gap-8 w-full max-w-xl'>
                        <div className='flex flex-col gap-3'>
                            <span className='flex gap-2 items-center py-2.5 px-3.5 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full'>
                                <img src={UserIcon} alt="User Icon" className='w-6 h-6' />
                                <p>Name: {patientDetails?.Name}</p>
                            </span>
                            <span className='flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full'>
                                <img src={MapPinIcon} alt="Location Icon" className='w-6 h-6 mr-2' />
                                <p>Place: {patientDetails?.Place}</p>
                            </span>
                            <span className='flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full'>
                                <img src={GenderIcon} alt="Gender Icon" className='w-6 h-6 mr-2' />
                                <p>Gender: {patientDetails?.Gender}</p>
                            </span>
                        </div>
                        <div className='flex flex-col gap-3'>
                            <span className='flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full'>
                                <img src={AgeIcon} alt="Age Icon" className='w-6 h-6 mr-2' />
                                <p>Age: {patientDetails?.Age}</p>
                            </span>
                            <span className='flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full'>
                                <img src={UserIdIcon} alt="Patient ID Icon" className='w-6 h-6 mr-2' />
                                <p>Patient ID: {patientDetails?.PatientID}</p>
                            </span>
                            <span className='flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full'>
                                <img src={FlaskIcon} alt="Lab Icon" className='w-6 h-6 mr-2' />
                                <p>Lab Number: {patientDetails?.LabNo}</p>
                            </span>
                        </div>
                    </div>
                </div>

                {/* Patient Profile and Actions */}
                <div className='flex flex-col items-center'>
                    <img className='w-28 h-28 rounded-full border-4 border-[#845BB3] mb-4' src={patientDetails?.imageURL} alt="Patient Profile" />
                    <img src={EditIcon} alt="Edit Icon" className='w-5 h-5 mb-4 cursor-pointer' />

                    <button onClick={handleRedirect} className='flex items-center bg-[#845BB3] text-white text-sm font-semibold px-4 py-2 rounded-lg'>
                        <p className='mr-2'>TEST AUTOMATIC</p>
                        <img src={AutomaticTestingIcon} alt="Automatic Testing Icon" className='w-6 h-6' />
                    </button>
                </div>
            </div>

            {/* Select Tests Component */}
            <SelectTests
                tests={tests}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
            />
        </div>
    );
}
