import React from 'react';
import adress from '../../../assets/ProfileCardimg/address.jpeg';
import summary from '../../../assets/ProfileCardimg/summary.jpeg';
import sidevector from "../../../assets/sidevector.svg";
import { Link } from 'react-router-dom';
import help from "../../../assets/help.svg";

const PatientBillingAddress = () => {
    return (
        <div className="bg-[#f3f1fc] p-8 rounded-xl w-full max-w-md mx-auto shadow-lg">
      <img src={adress} alt="mailbox" className="w-full h-44 object-cover rounded-xl mb-6" />
      <h2 className="text-center text-2xl font-bold text-[#7d57e6] mb-4">PATIENT BILLING ADDRESS</h2>
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-col items-center">
          <div className="w-6 h-6 rounded-full border-4 border-[#7d57e6] bg-[#7d57e6] mr-2"></div>
          <span className="text-sm font-semibold text-[#7d57e6]">CHECKOUT</span>
        </div>
        <div className="flex-1 h-0.5 bg-[#7d57e6]"></div>
        <div className="flex flex-col items-center">
          <div className="w-6 h-6 rounded-full border-4 border-[#7d57e6] bg-white mr-2"></div>
          <span className="text-sm font-semibold text-gray-600">BILLING</span>
        </div>
        <div className="flex-1 h-0.5 bg-[#7d57e6]"></div>
        <div className="flex flex-col items-center">
          <div className="w-6 h-6 rounded-full border-4 border-[#7d57e6] bg-white mr-2"></div>
          <span className="text-sm font-semibold text-gray-600">CONFIRMATION</span>
        </div>
      </div>
      <input
        type="text"
        placeholder="Patient Name"
        className="w-full border border-gray-300 rounded-xl p-3 mb-4 focus:outline-none focus:ring-2 focus:ring-[#7d57e6]"
      />
      <input
        type="text"
        placeholder="Address"
        className="w-full border border-gray-300 rounded-xl p-3 mb-4 focus:outline-none focus:ring-2 focus:ring-[#7d57e6]"
      />
      <input
        type="text"
        placeholder="Street"
        className="w-full border border-gray-300 rounded-xl p-3 mb-4 focus:outline-none focus:ring-2 focus:ring-[#7d57e6]"
      />
      <div className="flex gap-4">
        <input
          type="text"
          placeholder="Pincode"
          className="w-full border border-gray-300 rounded-xl p-3 mb-4 focus:outline-none focus:ring-2 focus:ring-[#7d57e6]"
        />
        <input
          type="text"
          placeholder="Phone"
          className="w-full border border-gray-300 rounded-xl p-3 mb-4 focus:outline-none focus:ring-2 focus:ring-[#7d57e6]"
        />
      </div>
      <div className="flex justify-evenly">
        <button className="bg-white border border-[#7d57e6] text-[#7d57e6] py-3 px-8 rounded-lg font-semibold hover:bg-gray-100 transition">Skip</button>
        <button className="bg-[#7d57e6] text-white py-3 px-8 rounded-lg font-semibold hover:bg-[#5a3dbf] transition">Proceed</button>
      </div>
    </div>
    );
  };
  
const OrderSummary = () => {
  return (
    <div className="bg-[#845BB3] p-8 rounded-xl w-full max-w-md mx-auto text-white shadow-lg">
      <img src={summary} alt="lab" className="w-full h-40 object-cover rounded-xl mb-6" />
      <h2 className="text-center text-2xl font-bold mb-4">ORDER SUMMARY</h2>
      <ul className="mb-6">
        <li className="flex justify-between items-center mb-4">
          <span>HEART RATE</span>
          <span className="cursor-pointer bg-white text-[#7d57e6] rounded-full w-6 h-6 flex items-center justify-center font-bold" title="Details about Heart Rate">i</span>
        </li>
        <li className="flex justify-between items-center mb-4">
          <span>BLOOD OXYGEN SATURATION</span>
          <span className="cursor-pointer bg-white text-[#7d57e6] rounded-full w-6 h-6 flex items-center justify-center font-bold" title="Details about Blood Oxygen Saturation">i</span>
        </li>
        <li className="flex justify-between items-center mb-4">
          <span>LIPID PROFILE</span>
          <span className="cursor-pointer bg-white text-[#7d57e6] rounded-full w-6 h-6 flex items-center justify-center font-bold" title="Details about Lipid Profile">i</span>
        </li>
      </ul>
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Have code? use your Discount code."
          className="flex-1 border border-gray-300 rounded-xl p-3 focus:outline-none focus:ring-2 focus:ring-white"
        />
        <button className="ml-4 bg-white text-[#7d57e6] py-3 px-6 rounded-xl font-semibold hover:bg-gray-200 transition">APPLY</button>
      </div>
      <div className="flex justify-between items-center mb-4">
        <span>Subtotal</span>
        <span>₹ 1,000</span>
      </div>
      <div className="flex justify-between items-center mb-4">
        <span>GST</span>
        <span>₹ 100</span>
      </div>
      <div className="flex justify-between items-center text-lg font-bold">
        <span>Total</span>
        <span>₹ 1,100</span>
      </div>
    </div>
  );
};

const PaymentAndOrderSummary = () => {
  return (
    <div className="flex flex-col md:flex-row gap-8 p-8 bg-[#C7CEE5] min-h-[92vh] items-center justify-center">
      <div className="w-full max-w-lg z-10">
        <PatientBillingAddress />
      </div>
      <div className="w-full max-w-lg z-10">
        <OrderSummary />
      </div>
        {/* Help Icon */}
        <Link to="../faq" className="fixed z-10 bottom-8 right-28 ">
          <img src={help} alt="helpicon" className="w-10 lg:w-12" />
        </Link>
      <div className="absolute bottom-0 right-0 z-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>
    </div>
  );
};

export default PaymentAndOrderSummary;