import React from "react";

import help from "../assets/help.svg";
import sidevector from "../assets/sidevector.svg";
import privacy from "../assets/privacy.png";
import Navbar from "../Components/Navbar";

export default function PrivacyPolicy() {
  return (
    <>
     {/* Navbar */}
    <Navbar/>
    <div className="bg-[#C7CEE5] min-h-screen py-10 px-6 lg:px-28 relative">
     
   
      {/* Privacy Policy */}

      <div className="relative mt-2 z-10 p-6 text-justify font-bold mb-20 rounded-lg">
        <h1 className="text-4xl text-[#845BB3] text-center font-bold">
          Privacy & Policy
        </h1>
        <p className="text-[#FF5D5D] text-lg text-center font-bold mt-4">
          Caution: Proceeding without rejection will result in the automatic
          acceptance of the consent.
        </p>
        <p className="mt-4 text-black">
          This consent form outlines the terms and conditions under which
          Superceuticals Private Limited ("the Company") may use your data,
          samples, and results for the purpose of developing company products
          and customizing delivery. Please read this form carefully before
          providing your consent. If you have any questions or concerns, please
          contact our Privacy Officer at sc@superceuticals.in.
        </p>

        <h4 className="text-[#6F3EF5] text-lg font-semibold mt-6">
          Purpose of Data and Sample Usage: By signing this consent form, you
          agree to allow the Company to use your provided data, samples, and
          test results for the following purposes:
        </h4>

        <ol className="list-decimal ml-10 mt-4 text-black space-y-4">
          <li>
            <strong>Product Development:</strong> The Company may utilize your
            data, samples, and results to improve, develop, and innovate new
            medical diagnostic products and services.
          </li>
          <li>
            <strong>Customization of Delivery:</strong> Your data and results
            may be used to enhance the delivery and customization of medical
            diagnostic services for you and other patients.
          </li>
          <li>
            <strong>Sharing with Stakeholders:</strong> The Company may share
            anonymized and aggregated data with stakeholders, partners, and
            collaborators for research, development, and quality improvement
            purposes.
          </li>
        </ol>

        <h2 className="text-[#6F3EF5] text-lg font-semibold mt-6">
          Sample Testing and Results:
        </h2>
        <ol className="list-decimal mt-4 ml-10 text-black space-y-4">
          <li>
            The Company will test the sample you provide to generate diagnostic
            results. These results will be communicated to you through a secure
            and confidential channel.
          </li>
          <li>
            While the Company maintains rigorous quality control standards, it's
            important to note that incorrect sampling or other factors may lead
            to inaccurate results. By signing this consent form, you acknowledge
            that the Company is not responsible for inaccurate results arising
            from incorrect sampling or external factors beyond its control.
          </li>
        </ol>

        <div className="flex justify-center  items-center mt-6">
          <input type="checkbox" id="agree" name="agree" className="mr-2" />
          <label htmlFor="agree" className=" text-[#6F3EF5]">
            I agree to the terms and conditions
          </label>
        </div>
      </div>

      {/* Background Image */}
      <div className="absolute bottom-0 z-0 left-0 ml-[-5%] w-full lg:w-auto">
        <img src={privacy} alt="sidevector" className="lg:w-[50%]" />
      </div>

      {/* Background Image */}
      <div className="absolute bottom-0 z-0 right-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>

      {/* Help Icon */}
      <div className="fixed bottom-8 right-8 lg:right-20">
        <img src={help} alt="helpicon" className="w-10 lg:w-12" />
      </div>
    </div>
    </>
  );
}
