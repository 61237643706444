import React from 'react';

export default function SelectTests({ tests, selectedGroups, setSelectedGroups }) {

    // Handles the change of individual test selections
    const handleOptionChange = (e, testFieldName, fieldName) => {
        const isChecked = e.target.checked;

        setSelectedGroups((prevSelected) => {
            const groupIndex = prevSelected.findIndex((group) => group.name === testFieldName);
            const updatedTests = isChecked
                ? [...(prevSelected[groupIndex]?.options || []), fieldName]
                : (prevSelected[groupIndex]?.options || []).filter((test) => test !== fieldName);

            if (groupIndex > -1) {
                if (updatedTests.length > 0) {
                    return [
                        ...prevSelected.slice(0, groupIndex),
                        { name: testFieldName, options: updatedTests },
                        ...prevSelected.slice(groupIndex + 1),
                    ];
                } else {
                    return [
                        ...prevSelected.slice(0, groupIndex),
                        ...prevSelected.slice(groupIndex + 1),
                    ];
                }
            } else {
                return [...prevSelected, { name: testFieldName, options: [fieldName] }];
            }
        });
    };

    // Handles the select/deselect all options for a test group
    const handleSelectAll = (testFieldName) => {
        setSelectedGroups((prevSelected) => {
            const groupIndex = prevSelected.findIndex((group) => group.name === testFieldName);
            const allOptions = tests.find(test => test.testFieldName === testFieldName).fields.map(field => field.fieldName);

            if (groupIndex > -1) {
                const group = prevSelected[groupIndex];

                if (group.options.length === allOptions.length) {
                    return prevSelected.filter((group) => group.name !== testFieldName);
                } else {
                    return [
                        ...prevSelected.slice(0, groupIndex),
                        { name: testFieldName, options: allOptions },
                        ...prevSelected.slice(groupIndex + 1),
                    ];
                }
            } else {
                return [...prevSelected, { name: testFieldName, options: allOptions }];
            }
        });
    };

    // Checks if an individual test is selected
    const isSelected = (testFieldName, fieldName) => {
        const group = selectedGroups.find((group) => group.name === testFieldName);
        return group ? group.options.includes(fieldName) : false;
    };

    // Checks if the entire test group is selected
    const isGroupSelected = (testFieldName) => {
        const group = selectedGroups.find((group) => group.name === testFieldName);
        const allOptions = tests.find(test => test.testFieldName === testFieldName).fields.map(field => field.fieldName);
        return group ? group.options.length === allOptions.length : false;
    };

    return (
        <div className="flex select-none flex-wrap gap-16 items-start justify-center">
            {tests.map((test, index) => (
                <div key={index} className="relative bg-white rounded-3xl shadow-md w-full min-w-[20rem] max-w-fit h-full min-h-[25rem]">
                    <div>
                        <div className="bg-[#845BB3] rounded-t-3xl py-5 text-center text-white">
                            <span className="font-semibold">{test.testFieldName}</span>
                        </div>
                        <div className={`mt-4 pt-2 pb-2 px-8 grid gap-x-14 ${test.fields.length > 6 ? 'grid-cols-2' : 'grid-cols-1'}`}>
                            {test.fields.map((field, idx) => (
                                <label key={idx} className="mt-3 gap-2 flex items-center">
                                    <input
                                        type="checkbox"
                                        id={field.fieldName}
                                        name={field.fieldName}
                                        checked={isSelected(test.testFieldName, field.fieldName)}
                                        onChange={(e) => handleOptionChange(e, test.testFieldName, field.fieldName)}
                                        className="h-4 w-4 px-5 z-10"
                                    />
                                    <p className="text-[#845BB3] font-medium">
                                        {field.fieldName}
                                    </p>
                                </label>
                            ))}
                        </div>
                    </div>
                    <button
                        onClick={() => handleSelectAll(test.testFieldName)}
                        className="bg-[#845BB3] text-white rounded-md m-6 px-6 py-2 text-sm font-bold absolute bottom-0 right-0"
                    >
                        {isGroupSelected(test.testFieldName) ? 'DESELECT ALL' : 'SELECT ALL'}
                    </button>
                </div>
            ))}
        </div>
    );
}
