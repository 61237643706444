import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    testsData: null,
    referedBy: "",
    testInstruction:null
}

const testSlice = createSlice({
    name: 'techSlice',
    initialState,
    reducers: {
        addTests: (state, action) => {
            state.testsData = action.payload;
        },
        addReferedBy: (state, action) => {
            state.referedBy = action.payload
        },
        addTestInstruction:(state,action)=>{
            state.testInstruction = action.payload
        }
    }
})

export const { addTests, addReferedBy , addTestInstruction } = testSlice.actions;
export default testSlice.reducer