import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SignupBackground from '../../assets/signup-background.png';
import { useSelector } from 'react-redux';

export default function AuthLayout() {

    const isAuthenticated = useSelector(state => state.authSlice.isAuthenticated);
    const token = localStorage.getItem('cookieFallback')

    if (isAuthenticated || token) {
        console.log("Bhag")
        console.log(isAuthenticated)
        console.log(token)
        return <Navigate to={'/add-patient'} />
    }

    return (
        <div className='w-full h-screen relative flex items-center justify-end'>
            <div style={{ backgroundImage: `url(${SignupBackground})` }} className='w-full h-screen absolute bg-no-repeat bg-cover bg-center blur -z-10' />
            <Outlet />

        </div>
    )
}
