import React from 'react';

export  const LipidProfile = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">Lipid Profile Summary</h2>
      <p className="mb-2">
        Cholesterol is a vital chemical present in the body and performs many functions such as coating the cells of the body, forming the cell membrane, and producing certain components called bile acids which aid in digestion of fats.
      </p>
      <p className="mb-2">
        Additionally, it performs an imperative role in the production of certain vital nutrients like vitamin D and sex hormones like estrogen, progesterone, and testosterone (steroid hormones).
      </p>
      <p className="mb-4">
        Elevated levels of cholesterol indicate a risk for heart disease, atherosclerosis, and stroke.
      </p>

      <h3 className="text-xl font-semibold text-[#845BB3] mb-2">Recommended by National Lipid Association 2014</h3>
      <h4 className="text-lg font-semibold text-[#845BB3] mb-2">For Adults</h4>

      {/* HbA1c Information */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">HbA1c Information</h3>
      <p className="mb-2">
        HbA1c provides an index of average blood glucose levels over the past 8 - 12 weeks and is a much better indicator of long-term glycemic control as compared to blood and urinary glucose determinations.
      </p>

      {/* ADA Criteria Table */}
      <h4 className="text-lg font-semibold text-[#845BB3] mb-2">ADA Criteria for HbA1c and Mean Plasma Glucose Levels</h4>
      <table className="min-w-full bg-white border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">HbA1c (%)</th>
            <th className="border border-gray-300 px-4 py-2">Mean Plasma Glucose (mg/dL)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">6</td>
            <td className="border border-gray-300 px-4 py-2">126</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">7</td>
            <td className="border border-gray-300 px-4 py-2">154</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">8</td>
            <td className="border border-gray-300 px-4 py-2">183</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">9</td>
            <td className="border border-gray-300 px-4 py-2">212</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">10</td>
            <td className="border border-gray-300 px-4 py-2">240</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">11</td>
            <td className="border border-gray-300 px-4 py-2">269</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">12</td>
            <td className="border border-gray-300 px-4 py-2">298</td>
          </tr>
        </tbody>
      </table>

      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Analyte</th>
            <th className="border border-gray-300 px-4 py-2">Optimal (mg/dL)</th>
            <th className="border border-gray-300 px-4 py-2">Above Optimal (mg/dL)</th>
            <th className="border border-gray-300 px-4 py-2">Borderline High (mg/dL)</th>
            <th className="border border-gray-300 px-4 py-2">High (mg/dL)</th>
            <th className="border border-gray-300 px-4 py-2">Very High (mg/dL)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Cholesterol, Total</td>
            <td className="border border-gray-300 px-4 py-2">&lt;200</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">200 – 239</td>
            <td className="border border-gray-300 px-4 py-2">&gt;= 240</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Triglycerides</td>
            <td className="border border-gray-300 px-4 py-2">&lt;150</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">150 – 199</td>
            <td className="border border-gray-300 px-4 py-2">200 – 499</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=500</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">HDL</td>
            <td className="border border-gray-300 px-4 py-2">&gt;40 (males) &gt;50 (females)</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">LDL</td>
            <td className="border border-gray-300 px-4 py-2">&lt;100</td>
            <td className="border border-gray-300 px-4 py-2">101 - 129</td>
            <td className="border border-gray-300 px-4 py-2">130 – 159</td>
            <td className="border border-gray-300 px-4 py-2">160 – 189</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=190</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Non HDL Cholesterol</td>
            <td className="border border-gray-300 px-4 py-2">&lt;130</td>
            <td className="border border-gray-300 px-4 py-2">130 – 159</td>
            <td className="border border-gray-300 px-4 py-2">160 - 189</td>
            <td className="border border-gray-300 px-4 py-2">190 – 219</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=220</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


export const  HbA1c = () => {
    return (
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">HbA1c Interpretation</h2>
        
        <h3 className="text-lg font-semibold text-[#845BB3] mb-2">As per American Diabetes Association</h3>
        <h4 className="text-md font-semibold text-[#845BB3] mb-2">Reference Group</h4>
        <table className="min-w-full bg-white border border-gray-300 mb-4">
          <thead>
            <tr className="bg-[#D7D4F8] text-[#845BB3]">
              <th className="border border-gray-300 px-4 py-2">HbA1c in %</th>
              <th className="border border-gray-300 px-4 py-2">Interpretation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">&lt; 5.7</td>
              <td className="border border-gray-300 px-4 py-2">Non Diabetic Adults</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">5.7 – 6.4</td>
              <td className="border border-gray-300 px-4 py-2">At risk (Pre Diabetes)</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">&gt;= 6.5</td>
              <td className="border border-gray-300 px-4 py-2">Diagnosing Diabetic</td>
            </tr>
          </tbody>
        </table>
  
        <h4 className="text-md font-semibold text-[#845BB3] mb-2">Therapeutic Goals for Glycemic Control</h4>
        <h5 className="font-semibold text-[#845BB3] mb-2">Age {'>'} 19 Years</h5>
        <p className="mb-2">Goal of therapy: <strong>&lt; 7</strong></p>
        <p className="mb-4">Action suggested: <strong>&gt; 8</strong></p>
  
        <h5 className="font-semibold text-[#845BB3] mb-2">Age {'<'} 19 Years</h5>
        <p className="mb-4">Goal of therapy: <strong>&lt; 7.5</strong></p>
  
        <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Note:</h3>
        <p className="mb-2">
          Any condition that shortens erythrocyte life like sickle cell disease, pregnancy (2nd and 3rd trimester), haemodialysis, recent blood loss or transfusion, or erythropoietin will falsely lower HbA1c levels regardless of assay method.
        </p>
        <p className="mb-2">
          In patients with HbA1c levels between 7 – 8%, the Glycemark test may be done to identify those with more frequent and severe hyperglycemic excursions.
        </p>
        <p className="mb-2">
          Low glycated haemoglobin (below 4%) in a non-diabetic individual is often associated with systemic inflammatory diseases, chronic anaemia (especially severe iron deficiency & haemolytic), chronic renal failure, and liver diseases. Clinical correlation is suggested.
        </p>
        <p className="mb-4">
          To estimate the eAG from the HbA1C value, the following equation is used: 
          <strong> eAG(mg/dl) = 28.7 * A1c - 46.7</strong>
        </p>
  
        <h4 className="text-md font-semibold text-[#845BB3] mb-2">Monitoring Glycemic Control</h4>
        <p className="mb-2">
          In known diabetic patients, the following values can be considered as a tool for monitoring glycemic control:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>Excellent Control: 6 to 7%</li>
          <li>Fair to Good Control: 7 to 8%</li>
          <li>Unsatisfactory Control: 8 to 10%</li>
          <li>Poor Control: More than 10%</li>
        </ul>
      </div>
    );
  };
  


