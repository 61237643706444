import React,{useEffect, useState} from 'react';
import coin from '../../../assets/ProfileCardimg/coin.jpeg';
import summary from '../../../assets/ProfileCardimg/summary.jpeg';
import rupee from '../../../assets/icons/rupee.svg';
import phonepe from '../../../assets/icons/phonepe.svg';
import sidevector from "../../../assets/sidevector.svg";
import { Link } from 'react-router-dom';
import help from "../../../assets/help.svg";
import { useSelector } from 'react-redux';

const PaymentDetails = ({checkTransaction,cashPayment,processPayment}) => {
  return (
    <div className="bg-[#f3f1fc] p-8 rounded-xl w-full max-w-md mx-auto shadow-lg">
      <img src={coin} alt="coin" className="w-full h-40 object-cover rounded-xl mb-6" />
      <h2 className="text-center text-2xl font-bold text-[#7d57e6] mb-4">PAYMENT DETAILS</h2>
      <p className="text-center text-sm text-gray-600 mb-6">Choose your payment methods</p>
      <div className="flex justify-center gap-6 mb-6">
        <button className=" text-white rounded-lg h-20 w-20 px-6 py-6 border hover:border-4 border-[#5a3dbf] font-semibold  transition" onClick={cashPayment}> <img src={rupee} alt="coin" className=" w-full  object-cover rounded-md  " /></button>
        <button className="bg-[#9372ec] rounded-lg h-20 w-20 px-4 py-4 border hover:border-4 border-[#5a3dbf] font-semibold  transition" onClick={processPayment}> <img src={phonepe} alt="coin" className="w-full  object-cover rounded-md " /></button>
      </div>
      <p className="text-center text-sm text-[#ff0000] mb-6">Your Payment Has Been Failed, Please Try Again - Money Deducted from your account? Contact Us.</p>
      <input
        type="text"
        placeholder="Enter Transaction ID"
        className="w-full border border-gray-300 rounded-xl p-3 mb-6 focus:outline-none focus:ring-2 focus:ring-[#7d57e6]"
      />
      <div className="flex justify-center">
       
        <button className="bg-[#7d57e6] text-white py-3 px-12 rounded-lg font-semibold hover:bg-[#5a3dbf] transition" onClick={checkTransaction}>Check Status</button>
      </div>
    </div>
  );
};


const OrderSummary = ({billItem,totalPrice,gstRate,discountAmount}) => {
  const [coupon,setCoupon] = useState('');
  console.log("___________ totalPrice",totalPrice,"______________ gstRate",gstRate);
  const tempGst = (totalPrice * gstRate);
  const gstAmount = Number(tempGst.toFixed(2));
  const grantTotal = Number(totalPrice+gstAmount).toFixed(2);

  const checkValidity = async () => {
    const url = 'http://localhost:2001/promocode/validate';
    
    try {
      // Make the API call
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjkxNTM3MTd9.nz7RcVPmqtuzRx47GUUGpRPRDQWYW1mHybuNqBVaZKA',  // Replace with actual token
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code: coupon })  // Make sure to stringify the body
      });
  
      // Handle the response
      if (response.ok) {
        const priceData = await response.json();
        console.log('Price Data:', priceData);
        // Do something with the price data here
      } else {
        console.error('Error in fetching price data:', response.status);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };
  

  console.log("__________ gstAmount",tempGst,gstAmount-discountAmount);

  return (
    <div className="bg-[#845BB3] p-8 rounded-xl w-full max-w-md mx-auto text-white shadow-lg">
      <img src={summary} alt="lab" className="w-full h-40 object-cover rounded-xl mb-6" />
      <h2 className="text-center text-2xl font-bold mb-4">ORDER SUMMARY</h2>
      <ul className="mb-6">
        {billItem.map((test, index) => (
          <li key={index} className="flex justify-between items-center mb-2">
            <span>{test.testName}</span>
            <span>
            {test.totalPrice}
            </span>
          </li>
        ))}
      </ul>
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Have code? use your Discount code."
          className="flex-1 border border-gray-300 rounded-xl p-3 focus:outline-none focus:ring-2 focus:ring-white text-black"
          value={coupon}
          onChange={(e)=>{setCoupon(e.target.value)}}
        />
        <button className="ml-4 bg-white text-[#7d57e6] py-3 px-6 rounded-xl font-semibold hover:bg-gray-200 transition" onClick={checkValidity}>APPLY</button>
      </div>
      <div className="flex justify-between items-center mb-4">
        <span>Subtotal</span>
        <span>₹ {totalPrice}</span>
      </div>
      <div className="flex justify-between items-center mb-4">
        <span>GST</span>
        <span>₹ {gstAmount}</span>
      </div>
      <div className="flex justify-between items-center text-lg font-bold">
        <span>Total</span>
        <span>₹ {grantTotal}</span>
      </div>
    </div>
  );
};

const PaymentAndOrderSummary = () => {

  const [billItem, setBillItem] = useState([]);
  const [totalPrice , setTotalPrice] = useState(0);
  const gstRate = 0.18;
  

  const inputArray = [
    {testFieldName: "Lipid Profile", fields: [
      {fieldName: "HCG", unitAndRange: "0-5 mIU/mL", value: "", fieldId: "test-001"},
      {fieldName: "LH", unitAndRange: "0-10 IU/L", value: "", fieldId: "test-002"},
      {fieldName: "T4", unitAndRange: "4.5-12.5 µg/dL", value: "", fieldId: "test-003"},
      {fieldName: "fT4", unitAndRange: "0.8-1.8 ng/dL", value: "", fieldId: "test-004"},
      {fieldName: "TSH WB", unitAndRange: "0.4-4.0 mIU/L", value: "", fieldId: "test-005"},
      {fieldName: "TSH", unitAndRange: "0.5-5.0 µIU/mL", value: "", fieldId: "test-006"}
    ]},
    {testFieldName: "Diabetic", fields: [
      {fieldName: "HbA1c", unitAndRange: "4.0-5.6%", value: "", fieldId: "4548-4"},
      {fieldName: "eAG", unitAndRange: "70-100 mg/dL (fasting)", value: "", fieldId: "SDB"},
      {fieldName: "U-Albumin", unitAndRange: "<30 mg/g creatinine", value: "", fieldId: "14957-5"}
    ]}
  ];

  const calculateTotalPrices = (inputArrayOne, inputArrayTwo) => {
    // Convert inputArrayTwo into a map for easier price lookup
    const priceMap = inputArrayTwo.reduce((acc, curr) => {
      // Parse the price to a number before storing it
      acc[curr.testName] = Number(curr.price); // Use Number() or parseFloat()
      return acc;
    }, {});
    
    const result = inputArrayOne.map(testGroup => {
      const totalPrice = testGroup.fields.reduce((sum, field) => {
        const price = priceMap[field.fieldName] || 0; // Get price from the map, default to 0
        return sum + price;
      }, 0);
    
      // Return the formatted object
      return {
        testName: testGroup.testFieldName,
        totalPrice
      };
    });
  
    setBillItem(result);
  }

  useEffect(async()=>{
    await getPriceOfTests(inputArray);
    
  },[])
  
  const getPriceOfTests = async (data) => {
    // Extract field names from the input array
    const testNames = inputArray.reduce((acc, test) => {
      test.fields.forEach(field => {
        acc.push(field.fieldName); // Collecting fieldName values
      });
      return acc;
    }, []);
    
    // Create the payload for the API request
    const requestData = {};
    testNames.forEach(name => {
      requestData[name] = name; // Using fieldName as both key and value
    });

    console.log(requestData)
  
    // API endpoint URL
    const url = 'http://localhost:2001/testPrice/get-total-price?centerid=CEN895496';
  
    try {
      // Make the API call
      const response = await fetch(url, {
        method: 'POST',
        headers: {   
          'Authorization': 'Brarer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJpYXQiOjE3MjY3MzY0NDh9.pHYwS6BkZMJWwch1J6HUtH2ikvq7aV1LXTkYoVRZ0r0',  // Replace with actual token
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });
  
      // Handle the response
      if (response.ok) {
        const priceData = await response.json();
        console.log('Price Data:', priceData);
        setTotalPrice(priceData?.totalPrice);
        calculateTotalPrices(inputArray,priceData?.testDetails);
        // Do something with the price data here

      } else {
        console.error('Error in fetching price data:', response.status);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };
  

  const processPayment = () =>{
    console.log("_______________ payment processed");
  };

  const cashPayment = () =>{
    console.log("_________________ cash payment");
  }

  const checkTransaction = () =>{
    console.log("__________________ check transtion");
  }

  return (
    <div className="flex flex-col md:flex-row gap-8 p-8 bg-[#C7CEE5] min-h-[92vh] items-center justify-center">
    <div className="w-full max-w-lg z-10">
        <OrderSummary  billItem={billItem} totalPrice={totalPrice} gstRate={gstRate} />
      </div>
      <div className="w-full max-w-lg z-10">
        <PaymentDetails processPayment={processPayment} cashPayment={cashPayment} checkTransaction={checkTransaction} />
      </div>
       {/* Help Icon */}
       <Link to="../faq" className="fixed z-10 bottom-8 right-28 ">
          <img src={help} alt="helpicon" className="w-10 lg:w-12" />
        </Link>
      <div className="absolute bottom-0 right-0 z-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>
    </div>
  );
};

export default PaymentAndOrderSummary;