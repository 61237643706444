import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   langData : 'english'
}

const langSlice = createSlice({
    name: 'langSlice',
    initialState,
    reducers: {

        addLang:(state ,action) =>{
            state.langData = action.payload
        }

    }
})

export const { addLang } = langSlice.actions;
export default langSlice.reducer