// src/hooks/useFetchPatients.js
import { useState, useEffect } from 'react';
import useDebounce from './useDebounce'; // Import the debounce hook

const useFetchPatients = (searchTerm) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Debounce the searchTerm with a delay of 2000 milliseconds
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Determine the URL based on whether there's a search term
      const url = debouncedSearchTerm
        ? `https://api.superceuticals.in/patient/search-patient/${debouncedSearchTerm}`
        : 'https://api.superceuticals.in/patient/search-patient/atm';

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk', // Replace with your correct Bearer token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setData(result.patients || []);
      } catch (error) {
        console.error('Error fetching patient data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [debouncedSearchTerm]);

  return { data, loading, error };
};

export default useFetchPatients;
